<template>
    <v-app>
        <v-app-bar :elevation="1">
            <v-app-bar-title>Data Management</v-app-bar-title>
            <template v-slot:append>
                <v-tooltip text="Generate Report" location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-btn icon="mdi-chart-box-multiple-outline" v-bind="props" @click="goTo('/generate-report')"></v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Admin Panel" location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-btn v-if="admin" icon="mdi-synagogue-outline" v-bind="props" @click="goTo('/admin/users')"></v-btn>
                    </template>
                </v-tooltip>
                <v-btn text icon="mdi-power" @click="logOut"></v-btn>
            </template>
        </v-app-bar>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="mt-20">
                    <div class="d-flex justify-between">
                        <h4 class="text-h4 text-secondary text-main-title mb-3">Attendance Towns</h4>
                        <v-btn v-if="showAttendance" class="text-secondary hover-secondary-btn" @click="handleDownload(attendance_towns, 'attendance_towns')"><v-icon>mdi-download</v-icon></v-btn>
                    </div>
                    <template v-if="showAttendance">
                        <v-data-table
                            :headers="attendance_towns_header"
                            :items="attendance_towns"
                            density="compact"
                        >
                            <template v-slot:headers>
                                <tr>
                                    <th sortable="true">No</th>
                                    <th class="text-center">Town</th>
                                    <th class="text-center min-w-[200px]">Attendance Level</th>
                                    <th class="text-center min-w-[250px]">Attendance Rate(Ytd,overall)</th>
                                    <th class="text-center min-w-[300px]">Attendance Rate(Ytd,19-20,overall)</th>
                                    <th class="text-center min-w-[250px]">Attendance Rate(Ytd,high)</th>
                                    <th class="text-center min-w-[280px]">Attendance Rate(Ytd,19-20,high)</th>
                                    <th class="text-center min-w-[220px]">Attendance DIff(overall)</th>
                                    <th class="text-center min-w-[240px]">Attendance Diff(high,needs)</th>
                                    <th class="text-center min-w-[180px]">Reporting Period</th>
                                    <th class="text-center min-w-[150px]">Update Date</th>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                    <template v-else>
                        <p class="text-h5 text-white bg-secondary text-center p-2">This source was disabled, Contact to support</p>
                    </template>
                </v-col>
                <v-col cols="12" class="mt-2">
                    <div class="d-flex justify-between">
                        <h4 class="text-h4 text-secondary text-main-title mb-3">School District</h4>
                        <v-btn v-if="showDistrict" class="text-secondary hover-secondary-btn" @click="handleDownload(school_district_models, 'school_district')"><v-icon>mdi-download</v-icon></v-btn>
                    </div>
                    <template v-if="showDistrict">
                        <v-data-table
                            :headers="school_district_models_header"
                            :items="school_district_models"
                            density="compact"
                        >
                            <template v-slot:headers>
                                <tr>
                                    <th class="text-center">Code</th>
                                    <th class="text-center min-w-[200px]">Name</th>
                                    <th class="text-center min-w-[250px]">Grades Inperson Model</th>
                                    <th class="text-center min-w-[300px]">Grades Hybrid Model</th>
                                    <th class="text-center min-w-[220px]">Grades Remote Model</th>
                                    <th class="text-center min-w-[280px]">Predominant Model</th>
                                    <th class="text-center min-w-[220px]">Organization Type</th>
                                    <th class="text-center min-w-[180px]">Reporting Period</th>
                                    <th class="text-center min-w-[150px]">Update Date</th>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                    <template v-else>
                        <p class="text-h5 text-white bg-secondary text-center p-2">This source was disabled, Contact to support</p>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
 </template>
  
<script>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { getAuth, signOut } from 'firebase/auth';
    import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore';

    export default {
        name: 'OverviewPage',
        setup() {
            const disabled = ref(false);
            const router = useRouter();
            const error = ref('');
            const admin = ref(false);
            const attendance_towns = ref([]);
            const school_district_models = ref([]);
            const showAttendance = ref(true);
            const showDistrict = ref(true);
            const attendance_towns_header = [
                { title: 'No', align: 'start', sortable: true, key: 'town_id'},
                { title: 'Town', align: 'center', sotable: true, key: 'town_name'},
                { title: 'Attendance Level', align: 'center', sotable: true, key: 'attendance_level'},
                { title: 'Attendance Rate(Ytd,overall)', align: 'center', sotable: true, key: 'attendance_rate_ytd_overall'},
                { title: 'Attendance Rate(Ytd,19-20,overall)', align: 'center', sotable: true, key: 'attendance_rate_19_20_overall'},
                { title: 'Attendance Rate(Ytd,high)', align: 'center', sotable: true, key: 'attendance_rate_ytd_high'},
                { title: 'Attendance Rate(Ytd,19-20,high)', align: 'center', sotable: true, key: 'attendance_rate_19_20_high'},
                { title: 'Attendance DIff(overall)', align: 'center', sotable: true, key: 'attendance_diff_overall'},
                { title: 'Attendance Diff(high,needs)', align: 'center', sotable: true, key: 'attendance_diff_high_needs'},
                { title: 'Reporting Period', align: 'center', sotable: true, key: 'reporting_period'},
                { title: 'Update Date', align: 'center', sotable: true, key: 'update_date'},
            ];
            const school_district_models_header = [
                { title: 'Code', align: 'center', sotable: true, key: 'district_code'},
                { title: 'Name', align: 'center', sotable: true, key: 'district_name'},
                { title: 'Grades Inperson Model', align: 'center', sotable: true, key: 'grades_inperson_model'},
                { title: 'Grades Hybrid Model', align: 'center', sotable: true, key: 'grades_hybrid_model'},
                { title: 'Grades Remote Model', align: 'center', sotable: true, key: 'grades_remote_model'},
                { title: 'Predominant Model', align: 'center', sotable: true, key: 'predominant_model'},
                { title: 'Organization Type', align: 'center', sotable: true, key: 'organization_type'},
                { title: 'Reporting Period', align: 'center', sotable: true, key: 'reporting_period'},
                { title: 'Update Date', align: 'center', sotable: true, key: 'update_date'},
            ];
            const logOut = () => {
                const auth = getAuth();
                signOut(auth).then(() => {
                    router.replace({ path: '/admin' });
                });
            };
            const goTo = (url) => {
                router.push(url);
            }

            const fetchData = async () => {
                const auth = getAuth();
                const uid = auth.currentUser.uid;
                if (!uid) {
                    router.push('/login');
                    return;
                }
                try {
                    const db = getFirestore();
                    const userDocRef = doc(db, 'users', uid);
                    const userDoc = getDoc(userDocRef);
                    const user = (await userDoc).data();
                    if (!user) {
                        router.push('/login');
                        return;
                    }
                    if(user.role === 'admin') admin.value = true;
                    if(!user.disabled || !user.disabled.length || !user.disabled.includes('attendance')) {
                        showAttendance.value = true;
                        const querySnapshot = await getDocs(collection(db, 'attendance_towns'));
                        querySnapshot.forEach((doc) => {
                            attendance_towns.value.push(doc.data());
                        });
                    } else {
                        showAttendance.value = false;
                    }
                    if(!user.disabled || !user.disabled.length || !user.disabled.includes('district')) {
                        showDistrict.value = true;
                        const schoolquerySnapshot = await getDocs(collection(db, 'school_district_models'));
                        schoolquerySnapshot.forEach((doc) => {
                            school_district_models.value.push(doc.data());
                        })
                    } else {
                        showDistrict.value = false;
                    }
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            };
            const convertToCSV = (data) => {
                if (data.length === 0) return '';

                const headers = Object.keys(data[0]);
                const rows = data.map(row => headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

                return [headers.join(','), ...rows].join('\r\n');
            };

            const replacer = (key, value) => value === null ? '' : value; // Handle null values

            const downloadCSV = (data, name) => {
                const csv = convertToCSV(data);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');

                if (link.download !== undefined) { // feature detection
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', name+'.csv');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            };

            const handleDownload = async (items, name) => {
                downloadCSV(items, name);
            };

            onMounted(() => {
                fetchData();
            });
        
            return {
                disabled,
                error,
                admin,
                attendance_towns,
                school_district_models,
                attendance_towns_header,
                school_district_models_header,
                showAttendance,
                showDistrict,
                handleDownload,
                goTo,
                logOut
            }
        }
    }
</script>