<template>
  <v-app>
    <v-main>
      <router-view v-slot="{ Component }">
        <component :is="Component"></component>
      </router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  
}
</script>
