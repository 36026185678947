<template>
    <v-app>
      <v-navigation-drawer v-model="drawer" app clipped>
        <v-list nav>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(menu, index) in menus"
              :key="index"
              @click="goto(menu.path)"
            >
              <v-list-item :prepend-icon="menu.icon" :title="menu.title"></v-list-item>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar :elevation="1">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-app-bar-title>Admin Panel</v-app-bar-title>
        <template v-slot:append>
          <v-btn text icon="mdi-power" @click="logOut"></v-btn>
        </template>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <v-row>
            <v-col>
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
  
      <v-footer app>
        <div class="flex text-center">
          <span class="lead text--secondary text-center">
            Admin Panel
          </span>
        </div>
      </v-footer>
    </v-app>
  </template>
  
<script>
  import { ref, reactive, onMounted, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { getAuth, signOut } from 'firebase/auth';
  export default {
    setup() {
        const router = useRouter();
        const drawer = ref(null);
        const dark = ref(false);
        const primaryColor = ref('primary');
        
        const path = '/admin';
        const menus = reactive([
          { path: '', title: 'Dashboard', icon: 'mdi-view-dashboard' },
          { path: '/users', title: 'Users', icon: 'mdi-account-group-outline' },
          { path: '/generate-report', title: 'Generate Report', icon: 'mdi-chart-box-multiple-outline'}
        ]);
        
        const goto = (newPath) => {
          if(newPath === '/generate-report') router.push(newPath);
          else router.push({ path: path + newPath }).catch(() => {});
        };
        
        const logOut = () => {
          const auth = getAuth();
          signOut(auth).then(() => {
            router.replace({ path: '/' });
          });
        };
        
        // Watch for dark mode changes
        watch(dark, (newVal) => {
          document.querySelector('html').classList.toggle('dark-mode', newVal);
        });
        
        // Load data when the component is mounted
        onMounted(() => {
          dark.value = document.querySelector('html').classList.contains('dark-mode');
        });
        return {
            router,
            drawer,
            primaryColor,
            menus,
            dark,
            goto,
            logOut
        }
    }
  }
</script>
  