<template>
    <v-app>
      <v-main>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
                <v-table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Fullname</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Disabled Sources</th>
                            <th>Attendance Report Columns</th>
                            <th>District Report Columns</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in users"
                            :key="item._id"
                        >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.firstname }} {{ item.lastname }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.role }}</td>
                            <td>
                              <v-select
                                v-model="item.disabled"
                                :items="disableArr"
                                min-width="260px"
                                :hide-details="true"
                                @update:modelValue="changeDisabled(item)"
                                multiple
                              >
                                <template v-slot:selection="{item, index}">
                                    <v-chip v-if="index < 2" color="secondary">
                                        <span class="text-[12px]">{{ item.title }}</span>
                                    </v-chip>
                                </template>
                              </v-select>
                            </td>
                            <td>
                              <v-select
                                v-model="item.attendance_report_columns"
                                :items="attendance_select_items"
                                :hide-details="true"
                                bg-color="white"
                                min-width="280px"
                                multiple
                                @update:modelValue="changeAttendanceReportColumns(item)"
                            >
                                <template v-slot:selection="{item, index}">
                                    <v-chip v-if="index < 2" color="secondary">
                                        <span class="text-[12px]">{{ item.title.substring(0,7) }}{{ item.value == "town_id" ? '' : '...' }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="text-grey text-caption aling-self-center"
                                    >
                                      ...
                                    </span>
                                </template>
                              </v-select>
                            </td>
                            <td>
                              <v-select
                                v-model="item.district_report_columns"
                                :items="district_select_items"
                                :hide-details="true"
                                bg-color="white"
                                min-width="280px"
                                multiple
                                @update:modelValue="changeDistrictReportColumns(item)"
                              >
                                <template v-slot:selection="{item, index}">
                                    <v-chip v-if="index < 2" color="secondary">
                                        <span class="text-[12px]">{{ item.title.substring(0,7) }}{{ (item.value == "district_code" || item.value=="district_name") ? '' : '...' }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="text-grey text-caption aling-self-center"
                                    >
                                      ...
                                    </span>
                                </template>
                              </v-select>
                            </td>
                            <td><v-btn icon="mdi-trash-can-outline" @click="deleteUser(item._id)"></v-btn></td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
<script>
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { getAuth, signOut } from 'firebase/auth';
  import { getFirestore, collection, getDocs, doc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
  import { useToast } from 'vue-toast-notification';
  import { getFunctions, httpsCallable } from 'firebase/functions';

  export default {
    setup() {
        const router = useRouter();
        const users = ref([]);
        const path = '/admin';
        const isAdmin = ref(false);
        const toast = useToast();
        const attendance_select_items= [
            { title: 'TOWN ID', value: 'town_id'},
            { title: 'Town NAME', value: 'town_name'},
            { title: 'Attendance Level', value: 'attendance_level'},
            { title: 'Attendance Rate(Ytd,overall)', value: 'attendance_rate_ytd_overall'},
            { title: 'Attendance Rate(Ytd,19-20,overall)', value: 'attendance_rate_19_20_overall'},
            { title: 'Attendance Rate(Ytd,high)', value: 'attendance_rate_ytd_high'},
            { title: 'Attendance Rate(Ytd,19-20,high)', value: 'attendance_rate_19_20_high'},
            { title: 'Attendance DIff(overall)', value: 'attendance_diff_overall'},
            { title: 'Attendance Diff(high,needs)', value: 'attendance_diff_high_needs'},
            { title: 'Reporting Period', value: 'reporting_period'},
            { title: 'Update Date', value: 'update_date'},
        ];
        const attendance_column_items = [
            'town_id',
            'town_name',
            'attendance_level',
            'attendance_rate_ytd_overall',
            'attendance_rate_19_20_overall',
            'attendance_rate_ytd_high',
            'attendance_rate_19_20_high',
            'attendance_diff_overall',
            'attendance_diff_high_needs',
            'reporting_period',
            'update_date'
        ];
        const district_select_items = [
          { title: 'Code', value: 'district_code'},
          { title: 'Name', value: 'district_name'},
          { title: 'Grades Inperson Model', value: 'grades_inperson_model'},
          { title: 'Grades Hybrid Model', value: 'grades_hybrid_model'},
          { title: 'Grades Remote Model', value: 'grades_remote_model'},
          { title: 'Predominant Model', value: 'predominant_model'},
          { title: 'Organization Type', value: 'organization_type'},
          { title: 'Reporting Period', value: 'reporting_period'},
          { title: 'Update Date', value: 'update_date'}
        ]
        const district_column_items = [
          'district_code',
          'district_name',
          'grades_inperson_model',
          'grades_hybrid_model',
          'grades_remote_model',
          'predominant_model',
          'organization_type',
          'reporting_period',
          'update_date'
        ];
        const goto = (newPath) => {
          router.push({ path: path + newPath }).catch(() => {});
        };
        const disableArr = ref(['attendance', 'district']);
        const fetchUsers = async () => {
            try {
                // Get the currently authenticated user
                const auth = getAuth();
                const currentUser = auth.currentUser;

                if (currentUser) {
                    // Fetch the current user's document from Firestore
                    const db = getFirestore();
                    const userDocRef = doc(db, 'users', currentUser.uid);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        // Check if the user is an admin
                        if (userData.role === 'admin') {
                            isAdmin.value = true;
                            // If the user is an admin, fetch all users from the 'users' collection
                            const usersCollection = collection(db, 'users');
                            const querySnapshot = await getDocs(usersCollection);
                            users.value = querySnapshot.docs.map(doc => {
                              const temp_attendace = doc.data().attendance_report_columns?.length ? doc.data().attendance_report_columns : attendance_column_items;
                              const temp_district = doc.data().district_report_columns?.length ? doc.data().district_report_columns : district_column_items;
                              return { id: doc.id, ...doc.data(), ...{
                                attendance_report_columns: temp_attendace,
                                district_report_columns: temp_district
                              }};
                            });
                        } else {
                            isAdmin.value = false;
                        }
                    } else {
                        console.error('User document does not exist');
                    }
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        const logOut = () => {
          const auth = getAuth();
          signOut(auth).then(() => {
            router.replace({ path: '/' });
          });
        };
        onMounted(() => {
            fetchUsers();
        })
        
        
      // Initialize Firebase Functions
        const deleteUserFromAuth = async (uid) => {
          try {
            const functions = getFunctions();
            const deleteUser = httpsCallable(functions, 'deleteUser');
            await deleteUser({ uid });
            toast.success('User deleted successfully');
          } catch (error) {
            console.error('Error deleting user:', error.message);
          }
        };

        const deleteUserFromFirestore = async (uid) => {
          try {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', uid);
            await deleteDoc(userDocRef);
          } catch (error) {
            console.error('Error deleting user document from Firestore:', error);
          }
        };

        const changeDisabled = async (item) => {
          const uid = item._id;
          if(uid) {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', uid);
            try {
              await updateDoc(userDocRef, {
                disabled: item.disabled
              });
              toast.success("User document updated successfully", {position: 'top-right'});
            } catch (error) {
              toast.error("Error updating user document");
              console.error('Error updating user document:', error);
            }
          }
        }
          
        const deleteUser = async (uid) => {
          try {
            await deleteUserFromFirestore(uid);
            await deleteUserFromAuth(uid);
            fetchUsers();
            toast.success('User deleted successfully', {position: 'top-right'});
          } catch (error) {
            toast.error('Check Firebase, uid:' + uid, {position: 'top-right'});
          }
        };

        const changeAttendanceReportColumns = async (item) => {
          const uid = item._id;
          if(uid) {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', uid);
            try {
              await updateDoc(userDocRef, {
                attendance_report_columns: item.attendance_report_columns
              });
              toast.success("User document updated successfully", {position: 'top-right'});
            } catch (error) {
              toast.error("Error updating user document");
              console.error('Error updating user document:', error);
            }
          }
        }
        const changeDistrictReportColumns = async (item) => {
          const uid = item._id;
          if(uid) {
            const db = getFirestore();
            const userDocRef = doc(db, 'users', uid);
            try {
              await updateDoc(userDocRef, {
                district_report_columns: item.district_report_columns
              });
              toast.success("User document updated successfully", {position: 'top-right'});
            } catch (error) {
              toast.error("Error updating user document");
              console.error('Error updating user document:', error);
            }
          }
        }
        return {
            users,
            attendance_select_items,
            district_select_items,
            disableArr,
            deleteUser,
            goto,
            changeDisabled,
            logOut,
            changeAttendanceReportColumns,
            changeDistrictReportColumns
        }
    }
  }
</script>