import { createVuetify } from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css';
import { mdi, aliases } from 'vuetify/iconsets/mdi';


export default createVuetify({
   components,
   directives,
   aliases: {
       VChipPrice: components.VChip
   },
   defaults: {
       VBtn: {
           class: 'text-none',
           style: [{letterSpacing: 'normal', boxShadow: 'none'}]
       },
       VTextField: {
           density: 'compact',
           variant: 'outlined',
           clearIcon: 'mdi-close',
       },
       VSelect: {
           density: 'compact',
           variant: 'outlined',
       },
       VChipPrice: {
           style: [{background: '#b6b6b6', fontSize: '1rem'}],
           class: 'text-white'
       },
       VChip: {
           style: 'font-size:1.3rem; text-transform: uppercase;',
           class: 'text-white'
       },
       VCard: {
           ripple: false,
           style: [{boxShadow: 'none', transitionDuration: '0s'}]
       },
       VDivider: {
           color: 'black',
           opacity: 1
       }
   },
   icons: {
       defaultSet: 'mdi',
       aliases,
       sets: { mdi }
   },
   theme: {
       defaultTheme: 'light',
       themes: {
           light: {
               dark: false,
               colors: {
                   primary: '#212529',
                   secondary: '#d63d12',
                   third:'#4fa23f',
                   accent: '#4C4B47',
                   cardbg: '#ffffff',
                   hiperprice: 'rgb(128,128,128)',
                   iconcolor: 'rgb(182,182,182)',
                   pricebackground: 'rgb(182,182,182)',
                   background: '#f4f4f4'
               }
           },
           dark: {
               dark: true,
               colors: {
                   primary: '#FFFF',
                   secondary: '#F4F4F4',
                   third: '#bdb8b8',
                   accent: '#272727',
                   cardbg: '#f3ffff',
                   hiperprice: '#FFFF',
                   iconcolor: '#FFFF',
                   pricebackground: 'rgb(182,182,182)',
                   background: '#0000'
               }
           }
       }
   }
})



