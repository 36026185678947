import { createRouter, createWebHistory } from 'vue-router';
import Overview from '@/views/Overview.vue';
import Reporter from '@/views/Reporter.vue';
import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';

import AdminDashboard from '@/views/admin/AdminDashboard.vue';
import AdminView from '@/views/admin/AdminHome.vue';
import AdminUser from '@/views/admin/AdminUser.vue';
import { getAuth } from 'firebase/auth';

const routes = [
  {
    path: '/', component: Overview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path:'/generate-report',
    component: Reporter,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login', component: Login
  },
  {
    path: '/register', component: Register
  },
  {
    path: '/admin',
    component: AdminView,
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '', component: AdminDashboard },
      { path: 'users', component: AdminUser },
    ],
  }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!currentUser) {
            next({path: '/login'});
        } else {
            next();
        }
    }
    else if(to.path === '/login') {
        if (currentUser) {
            next({ path: '/' });
        } else {
            next();
        }
    }
    else {
        next();
    }
});

export default router
