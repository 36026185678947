<template>
    <div id="user_login" class="pt-20">
        <v-container>
            <v-row>
                <v-col cols="12" md="10" offset-md="1">
                    <h4 class="text-h4 text-secondary text-main-title">Register</h4>
                </v-col>
                <v-col cols="12" style="padding: 0px;">
                    <form @submit.prevent="validate">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="5" offset-sm="1">
                                    <v-text-field
                                        v-model="firstname"
                                        type="text"
                                        class="bg-white"
                                        placeholder="John"
                                        hide-details="true"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="5">
                                    <v-text-field
                                        v-model="lastname"
                                        type="text"
                                        class="bg-white"
                                        placeholder="Doe"
                                        hide-details="true"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="10" offset-sm="1">
                                    <p class="text-body-2 text-[#4C4B47]">Email</p>
                                    <v-text-field
                                        v-model="email"
                                        type="email"
                                        class="bg-white"
                                        placeholder="admin@gmail.com"
                                        hide-details="true"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="10" offset-sm="1" class="pb-0">
                                    <p class="text-body-2 text-[#4C4B47]">Password</p>
                                    <v-text-field
                                        v-model="password"
                                        class="bg-white"
                                        type="password"
                                        placeholder="******"
                                        hide-details="true"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="10" offset-sm="1" class="flex justify-end pt-8">
                                    <v-btn size="large" class="text-secondary hover-secondary-btn" height="40px" type="submit" :loading="loginLoading">Register</v-btn>
                                </v-col>
                                <v-col cols="12" class="text-center">
                                    <p class="text-body-1 text-[#4C4B47]">Do you have account? <a href="/login" class="redirect-btn">Login</a></p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
            </v-row>
        </v-container>
    </div>
 </template>
  
<script>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
    import { setDoc, doc, getFirestore } from 'firebase/firestore';
    import { useToast } from 'vue-toast-notification';
    export default {
        name: 'UserLogin',
        setup() {
            const disabled = ref(false);
            const loginLoading = ref(false);
            const router = useRouter();
            const toast = useToast();
            const firstname = ref('');
            const lastname = ref('');
            const email = ref('');
            const showPassword = ref(false);
            const password = ref('');
            const error = ref('');
            
            const validate = async () => {
                const auth = getAuth();
                const db = getFirestore();
                disabled.value = true;
                try {
                    const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
                    const user = userCredential.user;
                    await setDoc(doc(db, 'users', user.uid), {
                        _id: user.uid,
                        firstname: firstname.value,
                        lastname: lastname.value,
                        email: email.value,
                        role: 'user'
                    })
                    toast.success('User registered and stored in Firestore!', {position: 'top-right'});
                    router.push('/login');
                } catch (err) {
                    if(err.toString().includes('auth/email-already-in-use')){
                        toast.error('email arelady exists', {position: 'top-right'});
                    } else {
                        toast.error('Server Error', {position: 'top-right'});
                    }
                }
            };
            return {
                firstname,
                lastname,
                email,
                disabled,
                loginLoading,
                password,
                showPassword,
                error,
                validate
            }
        }
    }
</script>
  