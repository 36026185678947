import { createApp } from 'vue'
import App from './App.vue'

import { onAuthStateChanged } from 'firebase/auth';
import { VueFire, VueFireAuth } from 'vuefire';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ToastPlugin from 'vue-toast-notification';
import './index.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

import {auth, firebaseApp} from '@/plugins/firebase';

let app;

onAuthStateChanged(auth, user => {
    store.dispatch('auth/fetchUser', user);
    if ( !app ) {
        app = createApp(App);

        app.use(store);
        app.use(router);
        app.use(vuetify);
        app.use(ToastPlugin, {
            duration: 2000,
            dismissible: true
        });
        app.use(VueFire, {
            firebaseApp,
            modules: [
                VueFireAuth()
            ]
        });

        app.mount('#app');
    }
});