<template>
    <div id="user_login" class="pt-20">
        <v-container>
            <v-row>
                <v-col cols="12" sm="10" offset-sm="1">
                    <h4 class="text-h4 text-secondary text-main-title">Login</h4>
                </v-col>
                <v-col cols="12" style="padding: 0px;">
                    <form @submit.prevent="validate">
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="10" offset-sm="1">
                                    <p class="text-body-2 text-[#4C4B47]">Email</p>
                                    <v-text-field
                                        v-model="username"
                                        type="email"
                                        class="bg-white"
                                        placeholder="admin@gmail.com"
                                        hide-details="true"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="10" offset-sm="1" class="pb-0">
                                    <p class="text-body-2 text-[#4C4B47]">Password</p>
                                    <v-text-field
                                        v-model="password"
                                        class="bg-white"
                                        type="password"
                                        placeholder="******"
                                        hide-details="true"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="10" offset-sm="1" class="pb-0 pt-0">
                                    <v-checkbox density="comfortable" hide-details="true" color="secondary" v-model="keepmelogged">
                                        <template v-slot:label>
                                            <p class="text-[#4C4B47] text-[14.4px]">Remember me</p>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="12" sm="10" offset-sm="1" class="flex justify-end pt-0">
                                    <v-btn size="large" class="text-secondary hover-secondary-btn" height="40px" type="submit" :loading="loginLoading">Sign In</v-btn>
                                </v-col>
                                <v-col cols="12" class="text-center">
                                    <p class="text-body-1 text-[#4C4B47]">Don't have account <a href="/register" class="redirect-btn">Register</a></p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </form>
                </v-col>
            </v-row>
        </v-container>
    </div>
 </template>
  
<script>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

    export default {
        name: 'UserLogin',
        setup() {
            const disabled = ref(false);
            const loginLoading = ref(false);
            const router = useRouter();
            const username = ref('');
            const showPassword = ref(false);
            const password = ref('');
            const error = ref('');
            const keepmelogged = ref(false);
            
            const emailRules = [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ];
            
            const passwordRules = [v => !!v || 'Password is required'];
            
            
            const validate = () => {
                disabled.value = true;
                const auth = getAuth();
                signInWithEmailAndPassword(auth, username.value, password.value)
                    .then(() => {
                        disabled.value = false;
                        router.replace({ path: '/' });
                    })
                    .catch(err => {
                        disabled.value = false;
                        error.value = err.message;
                    });
            };
            return {
                username,
                disabled,
                emailRules,
                loginLoading,
                password,
                showPassword,
                passwordRules,
                keepmelogged,
                error,
                validate
            }
        }
    }
</script>
  