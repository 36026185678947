<template>
    <v-app>
        <v-app-bar :elevation="1">
            <v-app-bar-title>Data Management</v-app-bar-title>
            <template v-slot:append>
                <v-tooltip text="Overview" location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-btn icon="mdi-view-grid-outline" v-bind="props" @click="goTo('/')"></v-btn>
                    </template>
                </v-tooltip>
                <v-tooltip text="Admin Panel" location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-btn v-if="admin" icon="mdi-synagogue-outline" v-bind="props" @click="goTo('/admin/users')"></v-btn>
                    </template>
                </v-tooltip>
                <v-btn text icon="mdi-power" @click="logOut"></v-btn>
            </template>
        </v-app-bar>
        <v-container fluid>
            <v-row>
                <v-col cols="12" class="mt-20">
                    <div class="d-flex justify-between">
                        <h4 class="text-h4 text-secondary text-main-title mb-3">Attendance Towns</h4>
                        <div v-if="showAttendance" class="d-flex justify-between gap-2 max-h-[40px]">
                            <v-select
                                v-model="attendance_items"
                                :items="attendance_select_items"
                                :hide-details="true"
                                bg-color="white"
                                min-width="300px"
                                multiple
                            >
                                <template v-slot:selection="{item, index}">
                                    <v-chip v-if="index < 2" color="secondary">
                                        <span class="text-[12px]">{{ item.title.substring(0,7) }}{{ item.value == "town_id" ? '' : '...' }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="text-grey text-caption aling-self-center"
                                    >
                                        (+{{ attendance_items.length - 2 }})
                                    </span>
                                </template>
                            </v-select>
                            <v-btn class="text-secondary hover-secondary-btn min-h-[40px]" @click="handleDownload(attendance_towns, 'attendance_towns')"><v-icon>mdi-download</v-icon></v-btn>
                        </div>
                    </div>

                    <template v-if="showAttendance">
                        <v-data-table
                            :headers="attendance_towns_header"
                            :items="attendance_towns"
                            :loading="attendance_towns_loading"
                            density="compact"
                        >
                            <template v-slot:headers="{ columns }">
                                <tr>
                                    <th
                                        v-for="(header, index) in columns"
                                        :key="header.key"
                                        class="bg-background"
                                        :class="[generateClass(header.title), index == 0 && 'pl-0']"
                                    >
                                        <v-text-field
                                            clearable
                                            hide-details="true"
                                            placeholder="search"
                                            class="bg-white"
                                            @update:model-value="value => customFilter(header.key, value)"
                                        ></v-text-field>
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        v-for="header in columns"
                                        :key="header.key"
                                        :class="header.key == 'town_id' ? 'text-left' : 'text-center'"
                                    >
                                        <p>{{ header.title }}</p>
                                    </th>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                    <template v-else>
                        <p class="text-h5 text-white bg-secondary text-center p-2">This source was disabled, Contact to support</p>
                    </template>
                </v-col>
                <v-col cols="12" class="mt-2">
                    <div class="d-flex justify-between">
                        <h4 class="text-h4 text-secondary text-main-title mb-3">School District</h4>
                        <div v-if="showDistrict" class="d-flex justify-between gap-2 max-h-[40px]">
                            <v-select
                                v-model="district_items"
                                :items="district_select_items"
                                :hide-details="true"
                                bg-color="white"
                                min-width="300px"
                                multiple
                            >
                                <template v-slot:selection="{item, index}">
                                    <v-chip v-if="index < 2" color="secondary">
                                        <span class="text-[12px]">{{ item.title.substring(0,7) }}{{ (item.value == "district_code" || item.value=="district_name") ? '' : '...' }}</span>
                                    </v-chip>
                                    <span
                                        v-if="index === 2"
                                        class="text-grey text-caption aling-self-center"
                                    >
                                        (+{{ district_items.length - 2 }})
                                    </span>
                                </template>
                            </v-select>
                            <v-btn class="text-secondary hover-secondary-btn min-h-[40px]" @click="handleDownload(school_district_models, 'school_district')"><v-icon>mdi-download</v-icon></v-btn>
                        </div>
                    </div>
                    <template v-if="showDistrict">
                        <v-data-table
                            :headers="school_district_models_header"
                            :items="school_district_models"
                            :loading="school_district_models_loading"
                            density="compact"
                        >
                            <template v-slot:headers="{ columns }">
                                <tr>
                                    <th
                                        v-for="(header, index) in columns"
                                        :key="header.key"
                                        class="bg-background"
                                        :class="[generateClass(header.title), index == 0 && 'pl-0']"
                                    >
                                        <v-text-field
                                            clearable
                                            hide-details="true"
                                            placeholder="search"
                                            class="bg-white"
                                            @update:model-value="value => customDistrictFilter(header.key, value)"
                                        ></v-text-field>
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        v-for="header in columns"
                                        :key="header.key"
                                        :class="header.key == 'district_code' ? 'text-left' : 'text-center'"
                                    >
                                        <p>{{ header.title }}</p>
                                    </th>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                    <template v-else>
                        <p class="text-h5 text-white bg-secondary text-center p-2">This source was disabled, Contact to support</p>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
 </template>
  
<script>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { getAuth, signOut } from 'firebase/auth';
    import { collection, doc, getDoc, getDocs, getFirestore, updateDoc } from 'firebase/firestore';
    import { watch } from 'vue';

    export default {
        name: 'ReporterPage',
        setup() {
            const auth = getAuth();
            const uid = auth.currentUser.uid;
            const db = getFirestore();
            const userDocRef = doc(db, 'users', uid);
            const disabled = ref(false);
            const router = useRouter();
            const error = ref('');
            const admin = ref(false);
            const attendance_towns = ref([]);
            const real_attendance_towns = ref([]);
            const attendance_towns_loading = ref(true);
            const school_district_models = ref([]);
            const real_school_district_models = ref([]);
            const school_district_models_loading = ref(true);
            const showAttendance = ref(true);
            const showDistrict = ref(true);
            const attendance_filters = ref({});
            const district_filters = ref({});
            const attendance_report_status = ref(false);
            const district_report_status = ref(false);
            const attendance_select_items= [
                { title: 'TOWN ID', value: 'town_id'},
                { title: 'Town NAME', value: 'town_name'},
                { title: 'Attendance Level', value: 'attendance_level'},
                { title: 'Attendance Rate(Ytd,overall)', value: 'attendance_rate_ytd_overall'},
                { title: 'Attendance Rate(Ytd,19-20,overall)', value: 'attendance_rate_19_20_overall'},
                { title: 'Attendance Rate(Ytd,high)', value: 'attendance_rate_ytd_high'},
                { title: 'Attendance Rate(Ytd,19-20,high)', value: 'attendance_rate_19_20_high'},
                { title: 'Attendance DIff(overall)', value: 'attendance_diff_overall'},
                { title: 'Attendance Diff(high,needs)', value: 'attendance_diff_high_needs'},
                { title: 'Reporting Period', value: 'reporting_period'},
                { title: 'Update Date', value: 'update_date'},
            ];
            const attendance_items = ref([
                { title: 'TOWN ID', value: 'town_id'},
                { title: 'Town NAME', value: 'town_name'},
                { title: 'Attendance Level', value: 'attendance_level'},
                { title: 'Attendance Rate(Ytd,overall)', value: 'attendance_rate_ytd_overall'},
                { title: 'Attendance Rate(Ytd,19-20,overall)', value: 'attendance_rate_19_20_overall'},
                { title: 'Attendance Rate(Ytd,high)', value: 'attendance_rate_ytd_high'},
                { title: 'Attendance Rate(Ytd,19-20,high)', value: 'attendance_rate_19_20_high'},
                { title: 'Attendance DIff(overall)', value: 'attendance_diff_overall'},
                { title: 'Attendance Diff(high,needs)', value: 'attendance_diff_high_needs'},
                { title: 'Reporting Period', value: 'reporting_period'},
                { title: 'Update Date', value: 'update_date'},
            ]);
            const attendance_towns_header_items = {
                town_id: { title: 'TOWN ID', align: 'start', sortable: true, key: 'town_id'},
                town_name: { title: 'Town NAME', align: 'center', sotable: true, key: 'town_name'},
                attendance_level: { title: 'Attendance Level', align: 'center', sotable: true, key: 'attendance_level'},
                attendance_rate_ytd_overall: { title: 'Attendance Rate(Ytd,overall)', align: 'center', sotable: true, key: 'attendance_rate_ytd_overall'},
                attendance_rate_19_20_overall: { title: 'Attendance Rate(Ytd,19-20,overall)', align: 'center', sotable: true, key: 'attendance_rate_19_20_overall'},
                attendance_rate_ytd_high: { title: 'Attendance Rate(Ytd,high)', align: 'center', sotable: true, key: 'attendance_rate_ytd_high'},
                attendance_rate_19_20_high: { title: 'Attendance Rate(Ytd,19-20,high)', align: 'center', sotable: true, key: 'attendance_rate_19_20_high'},
                attendance_diff_overall: { title: 'Attendance DIff(overall)', align: 'center', sotable: true, key: 'attendance_diff_overall'},
                attendance_diff_high_needs: { title: 'Attendance Diff(high,needs)', align: 'center', sotable: true, key: 'attendance_diff_high_needs'},
                reporting_period: { title: 'Reporting Period', align: 'center', sotable: true, key: 'reporting_period'},
                update_date: { title: 'Update Date', align: 'center', sotable: true, key: 'update_date'}
            };
            const attendance_towns_header = ref([
                { title: 'TOWN ID', align: 'start', sortable: true, key: 'town_id'},
                { title: 'TOWN NAME', align: 'center', sotable: true, key: 'town_name'},
                { title: 'Attendance Level', align: 'center', sotable: true, key: 'attendance_level'},
                { title: 'Attendance Rate(Ytd,overall)', align: 'center', sotable: true, key: 'attendance_rate_ytd_overall'},
                { title: 'Attendance Rate(Ytd,19-20,overall)', align: 'center', sotable: true, key: 'attendance_rate_19_20_overall'},
                { title: 'Attendance Rate(Ytd,high)', align: 'center', sotable: true, key: 'attendance_rate_ytd_high'},
                { title: 'Attendance Rate(Ytd,19-20,high)', align: 'center', sotable: true, key: 'attendance_rate_19_20_high'},
                { title: 'Attendance DIff(overall)', align: 'center', sotable: true, key: 'attendance_diff_overall'},
                { title: 'Attendance Diff(high,needs)', align: 'center', sotable: true, key: 'attendance_diff_high_needs'},
                { title: 'Reporting Period', align: 'center', sotable: true, key: 'reporting_period'},
                { title: 'Update Date', align: 'center', sotable: true, key: 'update_date'},
            ]);
            const district_select_items = [
                { title: 'Code', value: 'district_code'},
                { title: 'Name', value: 'district_name'},
                { title: 'Grades Inperson Model', value: 'grades_inperson_model'},
                { title: 'Grades Hybrid Model', value: 'grades_hybrid_model'},
                { title: 'Grades Remote Model', value: 'grades_remote_model'},
                { title: 'Predominant Model', value: 'predominant_model'},
                { title: 'Organization Type', value: 'organization_type'},
                { title: 'Reporting Period', value: 'reporting_period'},
                { title: 'Update Date', value: 'update_date'}
            ];
            const district_items = ref([
                { title: 'Code', value: 'district_code'},
                { title: 'Name', value: 'district_name'},
                { title: 'Grades Inperson Model', value: 'grades_inperson_model'},
                { title: 'Grades Hybrid Model', value: 'grades_hybrid_model'},
                { title: 'Grades Remote Model', value: 'grades_remote_model'},
                { title: 'Predominant Model', value: 'predominant_model'},
                { title: 'Organization Type', value: 'organization_type'},
                { title: 'Reporting Period', value: 'reporting_period'},
                { title: 'Update Date', value: 'update_date'}
            ]);
            const school_district_models_header_items = {
                district_code: { title: 'Code', align: 'start', sotable: true, key: 'district_code'},
                district_name: { title: 'Name', align: 'center', sotable: true, key: 'district_name'},
                grades_inperson_model: { title: 'Grades Inperson Model', align: 'center', sotable: true, key: 'grades_inperson_model'},
                grades_hybrid_model: { title: 'Grades Hybrid Model', align: 'center', sotable: true, key: 'grades_hybrid_model'},
                grades_remote_model: { title: 'Grades Remote Model', align: 'center', sotable: true, key: 'grades_remote_model'},
                predominant_model: { title: 'Predominant Model', align: 'center', sotable: true, key: 'predominant_model'},
                organization_type: { title: 'Organization Type', align: 'center', sotable: true, key: 'organization_type'},
                reporting_period: { title: 'Reporting Period', align: 'center', sotable: true, key: 'reporting_period'},
                update_date: { title: 'Update Date', align: 'center', sotable: true, key: 'update_date'}
            }
            const school_district_models_header = ref([
                { title: 'Code', align: 'start', sotable: true, key: 'district_code'},
                { title: 'Name', align: 'center', sotable: true, key: 'district_name'},
                { title: 'Grades Inperson Model', align: 'center', sotable: true, key: 'grades_inperson_model'},
                { title: 'Grades Hybrid Model', align: 'center', sotable: true, key: 'grades_hybrid_model'},
                { title: 'Grades Remote Model', align: 'center', sotable: true, key: 'grades_remote_model'},
                { title: 'Predominant Model', align: 'center', sotable: true, key: 'predominant_model'},
                { title: 'Organization Type', align: 'center', sotable: true, key: 'organization_type'},
                { title: 'Reporting Period', align: 'center', sotable: true, key: 'reporting_period'},
                { title: 'Update Date', align: 'center', sotable: true, key: 'update_date'}
            ]);
            const logOut = () => {
                const auth = getAuth();
                signOut(auth).then(() => {
                    router.replace({ path: '/admin' });
                });
            };
            const goTo = (url) => {
                router.push(url);
            }

            const fetchData = async () => {
                try {
                    const userDoc = getDoc(userDocRef);
                    const user = (await userDoc).data();
                    if (!user) {
                        router.push('/login');
                        return;
                    }
                    if(user.attendance_report_columns) {
                        const attendance_report_columns = user.attendance_report_columns;
                        if(attendance_report_columns.length) {
                            attendance_report_status.value = true;
                            attendance_items.value = attendance_report_columns;
                        }
                    }
                    if(user.district_report_columns) {
                        const district_report_columns = user.district_report_columns;
                        if(district_report_columns.length) {
                            district_report_status.value = true;
                            district_items.value = district_report_columns;
                        }
                    }
                    if(user.role === 'admin') admin.value = true;
                    if(!user.disabled || !user.disabled.length || !user.disabled?.includes('attendance')) {
                        showAttendance.value = true;
                        const querySnapshot = await getDocs(collection(db, 'attendance_towns'));
                        querySnapshot.forEach((doc) => {
                            attendance_towns.value.push(doc.data());
                            real_attendance_towns.value.push(doc.data());
                        });
                        attendance_towns_loading.value = false;
                    } else {
                        showAttendance.value = false;
                        attendance_towns_loading.value = false;
                    }
                    if(!user.disabled || !user.disabled.length || !user.disabled?.includes('district')) {
                        showDistrict.value = true;
                        const schoolquerySnapshot = await getDocs(collection(db, 'school_district_models'));
                        schoolquerySnapshot.forEach((doc) => {
                            school_district_models.value.push(doc.data());
                            real_school_district_models.value.push(doc.data());
                        })
                        school_district_models_loading.value = false;
                    } else {
                        showDistrict.value = false;
                        school_district_models_loading.value = false;
                    }
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            };
            const convertToCSV = (data) => {
                if (data.length === 0) return '';

                const headers = Object.keys(data[0]);
                const rows = data.map(row => headers.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

                return [headers.join(','), ...rows].join('\r\n');
            };

            const replacer = (key, value) => value === null ? '' : value; // Handle null values

            const downloadCSV = (data, name) => {
                const csv = convertToCSV(data);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');

                if (link.download !== undefined) { // feature detection
                    const url = URL.createObjectURL(blob);
                    link.setAttribute('href', url);
                    link.setAttribute('download', name+'.csv');
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            };

            const handleDownload = async (items, name) => {
                const c_date = new Date().toISOString().slice(0, 10);
                if(name == 'attendance_towns') {
                    if (items?.length>0) {
                        const filteredItems = items.map(item => {
                            const filteredItem = {};
                            attendance_items.value.forEach(key => {
                                if (item[key] !== undefined) {
                                    filteredItem[key] = item[key];
                                }
                            });
                            return filteredItem;
                        });
                        console.log(filteredItems);
                        downloadCSV(filteredItems, `${name}-${c_date}`);
                    }
                } else {
                    if (items?.length>0) {
                        const filteredItems = items.map(item => {
                            const filteredItem = {};
                            district_items.value.forEach(key => {
                                if (item[key] !== undefined) {
                                    filteredItem[key] = item[key];
                                }
                            });
                            return filteredItem;
                        });
                        downloadCSV(filteredItems, `${name}-${c_date}`);
                    }
                }
            };
            const generateClass = (title) => {
                if (title.toLowerCase()=="town name" || title.toLowerCase()=="town id" || title.toLowerCase()=='code' || title.toLowerCase()=='name') return 'min-w-[150px]';
                const len = title.length;
                if(len >= 34 ) return 'min-w-[300px]';
                else if (len >= 31) return 'min-w-[280px]';
                else if (len >= 27) return 'min-w-[250px]';
                else if (len >= 25) return 'min-w-[230px]';
                else if (len >= 24) return 'min-w-[220px]';
                else if (len >= 19) return 'min-w-[200px]';
                else if (len >= 16) return 'min-w-[180px]';
                else if (len >= 11) return 'min-w-[150px]';
                return '';
            }
            const customFilter = (type, value) => {
                if(value?.length) attendance_filters.value[type] = value;
                else delete attendance_filters.value[type];
                attendance_towns.value = [];
                const fil_length = Object.keys(attendance_filters.value).length;
                let i = 0;
                if(fil_length > 0) {
                    real_attendance_towns.value.forEach((item) => {
                        i = 0;
                        for (let key in attendance_filters.value) {
                            if(item[key]?.includes(attendance_filters.value[key])) i ++;
                        }
                        if (i === fil_length) attendance_towns.value.push(item);
                    })
                } else {
                    if (real_attendance_towns.value.length > attendance_towns.value.length) {
                        attendance_towns.value = real_attendance_towns.value;
                    }
                }
            }
            const customDistrictFilter = (type, value) => {
                if(value?.length) district_filters.value[type] = value;
                else delete district_filters.value[type];
                school_district_models.value = [];
                const fil_length = Object.keys(district_filters.value).length;
                let i = 0;
                if(fil_length > 0) {
                    real_school_district_models.value.forEach((item) => {
                        i = 0;
                        for (let key in district_filters.value) {
                            if(item[key]?.includes(district_filters.value[key])) i ++;
                        }
                        if (i === fil_length) school_district_models.value.push(item);
                    })
                } else {
                    if (real_school_district_models.value.length > school_district_models.value.length) {
                        school_district_models.value = real_school_district_models.value;
                    }
                }
            }
            watch(
                attendance_items,
                async (val) => {
                    const temp = [];
                    Object.keys(attendance_towns_header_items).forEach(item => {
                        if(val?.includes(item)) temp.push(attendance_towns_header_items[item]);
                    });
                    attendance_towns_header.value = temp;
                    if(attendance_report_status.value) {
                        attendance_report_status.value = false;
                    } else {
                        await updateDoc(userDocRef, {
                            attendance_report_columns: val
                        });
                    }
                }
            )
            watch(
                district_items,
                async (val) => {
                    const temp = [];
                    Object.keys(school_district_models_header_items).forEach(item => {
                        if(val?.includes(item)) temp.push(school_district_models_header_items[item]);
                    });
                    school_district_models_header.value = temp;
                    if(district_report_status.value) {
                        district_report_status.value = false;
                    } else {
                        await updateDoc(userDocRef, {
                            district_report_columns: val
                        });
                    }
                }
            )
            onMounted(() => {
                fetchData();
            });
        
            return {
                disabled,
                error,
                admin,
                attendance_towns,
                attendance_towns_loading,
                school_district_models,
                school_district_models_loading,
                attendance_select_items,
                attendance_items,
                attendance_towns_header,
                district_select_items,
                district_items,
                school_district_models_header,
                showAttendance,
                showDistrict,
                generateClass,
                customFilter,
                customDistrictFilter,
                handleDownload,
                goTo,
                logOut
            }
        }
    }
</script>